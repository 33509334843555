
import BrandLogo from '~/components/BrandLogo'

export default {
  name: 'MainNavigation',
  components: { BrandLogo },
  props: {
    menuPage: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      menuActive: false,
      mobileActive: false
    }
  },
  computed: {
    mainNavigations () {
      return this.$store.state.MainNavigation.items
    }
  },
  mounted () {
    this.$store.dispatch('MainNavigation/fetchNavigationData')
  },
  methods: {
    subMenu () {
      this.menuActive = !this.menuActive
    },
    mobileMenu () {
      this.mobileActive = !this.mobileActive
    }
  }
}

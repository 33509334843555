
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import MainNavigation from '~/components/MainNavigation'
import LoadingComponent from '~/components/LoadingComponent'
import FooterNavigation from '~/components/FooterNavigation'

export default {
  name: 'AboutPage',
  components: {
    FooterNavigation,
    MainNavigation,
    VueSlickCarousel,
    LoadingComponent
  },
  data: () => {
    return {
      pageTitle: '',
      pageBody: '',
      pageGallery: [],
      pageLoaded: false,
      pageId: '',
      slickOptions: {
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3500,
        cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
        fade: true,
        pauseOnHover: false
      }
    }
  },
  head () {
    return {
      title: 'Rekam Dua Cahaya - ajimahareshi photography'
    }
  },
  computed: {
    pageContent () {
      return this.$store.getters['HomePage/getHomeData']
    }
  },
  watch: {
    pageContent () {
      if (Object.keys(this.$store.getters['HomePage/getHomeData']).length > 0) {
        this.updatePageData()
      }
    }
  },
  mounted () {
    this.$store.dispatch('HomePage/fetchHomeData')
    this.updatePageId()
  },
  methods: {
    updatePageData () {
      this.pageTitle = this.pageContent.title.rendered
      this.pageBody = this.pageContent.content.rendered
      this.pageGallery = this.pageContent.acf.image_gallery
      this.pageLoaded = true
    },
    updatePageId () {
      const urlParam = this.$route.fullPath
      this.pageId = !urlParam.includes('blog') ? 'homepage' : 'blogpage'
    }
  }
}
